<template>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
        color="#F2BA55"
      ></v-progress-circular>
    </v-overlay>
</template>
<script>
export default {
    name: 'CircularProgress',
    data: () => ({
      overlay: true,
    })
}
</script>